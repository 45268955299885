<template>
  <b-card no-body>
    <b-row class="m-2">
      <b-col xs="12" md="6">
        <h2>Top-Up Your Account</h2>
        <p class="mt-2">Kindly select your preferred payment method below</p>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="paymentType"
            :aria-describedby="ariaDescribedby"
            name="paymentType"
            value="mpesa"
            >M-Pesa Payment Service</b-form-radio
          >
          <b-form-radio
            v-model="paymentType"
            :aria-describedby="ariaDescribedby"
            name="paymentType"
            value="bank-eft"
            class="mt-2"
            >Bank EFT Payment Service</b-form-radio
          >
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="next-btn mt-2"
          block
          @click="paymentMethod"
          :disabled="
            this.paymentType != 'mpesa' && this.paymentType != 'bank-eft'
          "
        >
          <span class="align-middle">Next</span>

          <feather-icon icon="ArrowRightIcon" class="ml-50" />
        </b-button>
      </b-col>

      <b-col xs="12" md="6" class="mt-2 mt-md-0">
        <h2>Calculate SMS Units</h2>
        <p class="mt-2">
          Kindly enter a transaction amount in KSH and we will let you know how many sms
          units you will get for that amount
        </p>
        <div class="d-flex align-items-baseline">
          <b-form-group label-for="amount">
            <b-form-input
              id="amount"
              v-model.number="amount"
              name="amount"
              type="number"
              placeholder="XXXX"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="ml-2"
            @click="calculateSmsUnits"
            :disabled="amount == '' || amount == null || amount == 0 || smsUnitsLoading"
          >
            <b-spinner small v-if="smsUnitsLoading" />
            <span v-if="smsUnitsLoading">&nbsp;</span>
            Calculate
          </b-button>
        </div>
        <div
          class="btn-link"
          v-if="smsUnits != '' && smsUnits != null && !smsUnitsLoading"
        >
          You will get <strong>{{ smsUnits }}</strong> SMS Units
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BFormRadio,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCol,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCol,
    BRow,
  },
  data() {
    return {
      paymentType: null,
      amount: null,
      smsUnitsLoading: false,
      smsUnits: null,
    };
  },
  methods: {
    paymentMethod() {
      if (this.paymentType == "mpesa" || this.paymentType == "bank-eft") {
        this.$router.push({ name: this.paymentType });
      }
    },
    calculateSmsUnits(){
      if(this.amount != '' && this.amount != null && this.amount != 0){
        this.smsUnitsLoading = true;
        this.$http
          .get("/credits/sms-units-for/" + this.amount)
          .then( response => {
            this.smsUnits = response.data.data.units;
            this.smsUnitsLoading = false;
          })
          .catch(error => {
            for (let err of error.response.data.error) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title:err,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            }
            this.smsUnitsLoading = false;
          })
      }
    }
  },
};
</script>

<style scoped>
.next-btn {
  /* border-radius: 0px 0px 0.358rem 0.358rem; */
  max-width: 24rem;
}
</style>
